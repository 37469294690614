import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { SiteMetaConfig } from "gatsby-theme-0/src/config/config-data"
import loadable from "@loadable/component"
const Header = loadable(() => import("gatsby-theme-0/src/components/header"))
const Footer = loadable(() => import("gatsby-theme-0/src/components/footer"))
const Posts = loadable(() => import("./posts"))
const Pagination = loadable(() => import("./pagination"))
import "./post.css"


const ListPosts = ({ data, pageContext, location }) => {

  const posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = pageContext
  
  return (
    <>
      <GatsbySeo
        title={`All Post Page: ${currentPage} | ${SiteMetaConfig.site_name}`}
        description={SiteMetaConfig.site_description}
        canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
        openGraph={{
          type: "website",
          url: `${SiteMetaConfig.site_url}${location.pathname}`,
          title: `All Post Page: ${currentPage} | ${SiteMetaConfig.site_name}`,
          description: SiteMetaConfig.site_description,
          images: [
            { url: SiteMetaConfig.site_image },
          ],
          site_name: SiteMetaConfig.site_name,
        }}
        twitter={{
          handle: `@${SiteMetaConfig.site_twitter_handle}`,
          site: `@${SiteMetaConfig.site_name}`,
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: SiteMetaConfig.site_facebook_app_id,
        }}
        htmlAttributes={{
          lang: SiteMetaConfig.site_language,
        }}
      />
      <Header />
      <div id="listposts_1662985012775" className="Page_Container">
        <div id="listposts_1662985014704" className="Heading_Text">All Post</div>
        <div id="listposts_1662985016703" className="Normal_Text">{`Page: ${currentPage}`}</div>
        <hr />
        <div id="listposts_1662985018248" className="Flex_Container">
          {posts.map(({ node }) => {
            return(
              <Posts
                key={node.fields.slug}
                postsMain={node.html.replace(/<\/?[^>]+(>|$)/g, "")}
                postsSlug={node.fields.slug}
                postsBaseUrl={SiteMetaConfig.site_url}
                postsTo={node.frontmatter.to}
                postsName={node.frontmatter.name}
                postsGLTF={node.frontmatter.gltf}
                postsTags={node.frontmatter.tags}
              />
            ) 
          })}
        </div>
        <Pagination
          paginationCurrentPage={currentPage}
          paginationNumPages={numPages}
          paginationPrevTo={`/blog/blog-page-${currentPage - 1}`}
          paginationNextTo={`/blog/blog-page-${currentPage + 1}`}
        />
      </div>
      <Footer CustomBottomHeight="yes" />
    </>
  )
}

export const listPostsQuery = graphql`
  query listPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            to
            name
            gltf
            tags
          }
        }
      }
    }
  }
`

export default ListPosts